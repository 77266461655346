<template>
    <div class="extra-login">
        <span>Sign-In with</span>
        <div class="login-social">
            <a class="google-login" href="#" @click="handleGoogleLogin"><i class="fab fa-google"></i></a>
            <!-- <a class="fb-login" href="#"><i class="fab fa-linkedin"></i></a>
            <a class="fb-login" href="#"><i class="fab fa-facebook-f"></i></a>
            <a class="tw-login" href="#"><i class="fab fa-twitter"></i></a> -->
        </div>
    </div>
</template>


<script>
import { mapActions } from "vuex";
import AuthMixin from "@/mixins/AuthMixin.js";

export default {

    mixins: [AuthMixin],

    methods: {
        ...mapActions("auth", ["googleLogin"]),

        loginCallback() {
            this.closeLogin();
            this.$toast.success(`Login Successfully.`, {
                position: "top-right",
            });
            this.$router.push({ path: '/account' });
        },

        handleGoogleLogin() {
            this.googleLogin().then(this.loginCallback)
                .catch((message) => {
                    this.$toast.error(`Login Failed! ${message}`, {
                        position: "top-right",
                    });
                });
        },


    },
};
</script>