<template>
    <div class="listview-wrapper mb-3 min-w-100">
        <div class="row">
            <div v-if="employer.file?.path || false" class="col-lg-2 col-md-2 col-xs-12">
                <div class="job-logo">
                    <img :src="employer.file.path" alt="" class="d-block" />
                </div>
            </div>
            <div :class="employer.file?.path || false ? 'col-lg-6 col-md-6' : 'col-lg-8 col-md-8'">
                <div class="job-details" id="jobSection">
                    <a href="#"
                        ><h3>{{ employer.name }}</h3></a
                    >
                    <p class="company-location" v-if="employer.location || false">
                        <i class="fas fa-map-marker-alt"></i>
                        {{ `${employer.location.address || ""}, ${employer.location.atoll.name || ""}` }}
                    </p>
                    <div class="mt-2 company-info">
                        <p class="float-left mr-4" v-if="employer.employment_sector">
                            <i class="fas fa-briefcase"> </i>
                            {{ employer.employment_sector }}
                        </p>
                        <p class="float-left mr-4" v-if="employer.since">
                            <i class="fas fa-user-times"> </i>
                            {{ "Since " + employer.since }}
                        </p>
                        <p class="float-left">
                            <i class="fas fa-users"> </i>
                            {{ employer.team_size || 1 }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-xs-12 text-right">
                <div v-if="isAccount || false" class="location mb-2 col-md-10 float-right">
                    <p class="float-right text-danger" @click="destroy(employer.id)">
                        <i class="far fa-trash-alt"></i> Delete
                    </p>
                    <router-link
                        :to="{
                            name: 'account-employer-edit',
                            params: { id: employer.id },
                        }"
                    >
                        <p class="float-right mr-4 text-success"><i class="far fa-edit"></i> Edit</p>
                    </router-link>
                </div>
                <div class="location mb-4 col-md-10 float-right">
                    <p class="float-right">
                        <i class="far fa-bookmark"></i> {{ employer.open_opportunities || 0 }} Open Jobs
                    </p>
                    <p v-if="!isAccount || false" class="float-right mr-4"><i class="far fa-heart"></i> Follow</p>
                </div>
                <router-link
                    :to="{
                        name: 'employer-show',
                        params: { id: employer.id },
                    }"
                >
                    <button class="btn" :class="{ 'btn-border': isAccount }">View Profile</button>
                </router-link>
            </div>
        </div>
        <hr v-if="isAccount || false" />
    </div>
</template>

<style scoped>
    .listview-wrapper .job-details {
        margin: 0 !important;
    }
</style>

<script>
    import { mapActions } from "vuex";

    export default {
        props: ["employer", "isAccount"],

        methods: {
            ...mapActions("employer", ["getAccountEmployers", "destroyEmployer"]),

            destroy(id) {
                this.destroyEmployer(id).then(() => {
                    this.getAccountEmployers();
                    this.successToast();
                }).catch(() => this.errorToast());
            },
        },
    };
</script>