<template>
    <section class="how-it-works section">
        <div class="container">
            <div class="section-header">
                <h2 class="section-title">How It Works?</h2>
                <p>{{ howItWork.how_it_work_subtitle }}</p>
            </div>
        </div>

        <div class="mt-4">
            <div class="container">
                <div class="row seven-cols">
                    <div
                        v-for="item in howItWork.data"
                        :key="item.id"
                        class="col-lgs-1 col-mds-3 col-sms-4 how-work-section"
                    >
                        <div class="work-process">
                            <span v-if="item.file?.path || false" class="process-icon">
                                <img :src="item.file.path" />
                            </span>
                            <h4>{{ item.title }}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState, mapActions } from "vuex";

    export default {

        computed: {
            ...mapState("appearance", ["howItWork"]),
        },

        mounted() {
            this.getHowItWorkSection();
        },

        methods: {
            ...mapActions("appearance", ["getHowItWorkSection"]),
        },
    };
</script>
