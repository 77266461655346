<template>
    <a class="job-listings" href="#">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-xs-12">
                <div v-if="opportunity.employer_logo" class="job-company-logo">
                    <img :src="opportunity.employer_logo" alt="" />
                </div>
                <div class="job-details">
                    <h3>{{ opportunity.title || "" }}</h3>
                    <span class="company-neme">{{ opportunity.employer_name || "" }}</span>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-3 text-right m-home-job">
                <div class="location">
                    <i class="fas fa-map-marker-alt"></i>
                    {{ opportunity.address || "" }}
                </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-3 text-center m-home-job">
                <div class="location"><i class="far fa-clock"></i> {{ opportunity.publish_date_human || "" }}</div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-12 text-center">
                <router-link :to="{
                    name: 'opportunity-show',
                    params: { id: opportunity.id },
                }" class="m-home-job mr-3"><span class="btn-apply">View Job</span><br /></router-link>
                <p class="mt-3 text-center m-home-job">
                    <i class="fas fa-archive"></i> {{ opportunity.employment_type }}
                </p>
            </div>
        </div>
    </a>
</template>


<script>

export default {
    props: ["opportunity"],
};
</script>