<template>
    <div>
        <!--top bannner-->
        <header-banner />
        <!--top bannner-->

        <!--client section-->
        <client-who-trust />
        <!--client section-->

        <!--recent opportunities-->
        <recent-opportunities />
        <!--recent opportunities-->

        <!--how its work-->
        <how-it-work />
        <!--how its work-->

        <!--browse category-->
        <popular-categories />
        <!--browse category-->

        <!--why choose us-->
        <why-choose-us />
        <!--why choose us-->

        <!--app section-->
        <app-download />
        <!--app section-->
    </div>
</template>

<script>
    import HeaderBanner from "./components/HeaderBanner.vue";
    import ClientWhoTrust from "./components/ClientWhoTrust.vue";
    import RecentOpportunities from "./components/RecentOpportunities.vue";
    import HowItWork from "./components/HowItWork.vue";
    import PopularCategories from "./components/PopularCategories.vue";
    import WhyChooseUs from "./components/WhyChooseUs.vue";
    import AppDownload from "./components/AppDownload.vue";

    export default {
        name: "home",

        components: {
            HeaderBanner,
            ClientWhoTrust,
            RecentOpportunities,
            HowItWork,
            PopularCategories,
            WhyChooseUs,
            AppDownload,
        }
    };
</script>