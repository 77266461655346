<template>
    <div class="account-popup-area signin-popup-box" id="loginForm">
        <div class="account-popup">
            <div class="page-login-form">
                <span class="close-popup" @click="closeLogin"><i class="fa fa-close"></i></span>
                <h3>Login</h3>
                <form @submit.prevent="submit" class="login-form">
                    <div class="form-group">
                        <div class="input-icon">
                            <i class="fa fa-user"></i>
                            <input type="text" id="sender-email" class="form-control" name="email"
                                placeholder="Email or Phone Number" v-model="user.email" />
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-icon">
                            <i class="fa fa-lock"></i>
                            <input type="password" class="form-control" placeholder="Password" v-model="user.password" />
                        </div>
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                        <label class="form-check-label" for="exampleCheck1">Remember me</label>
                    </div>
                    <button type="submit" class="btn btn-common log-btn">Sign In</button>
                </form>
                <ul class="form-links">
                    <p class="text-center">
                        Already have an account?
                        <a class="open-button" @click="openRegister"> Sign Up</a>
                    </p>
                </ul>
            </div>
            <social-login />
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import AuthMixin from "@/mixins/AuthMixin.js";
import SocialLogin from "./SocialLogin.vue";

export default {
    name: "login",

    components: {
        SocialLogin,
    },

    data: function () {
        return {
            user: {
                email: "",
                password: "",
            },
        };
    },

    mixins: [AuthMixin],

    methods: {
        ...mapActions("auth", ["login"]),

        submit() {
            this.login(this.user)
                .then(() => {
                    this.closeLogin();
                    this.clearForm();
                    this.$toast.success(`Login Successfully.`, {
                        position: "top-right",
                    });
                    this.$router.push({ path: '/account' });
                })
                .catch((message) => {
                    this.$toast.error(`Login Failed! ${message}`, {
                        position: "top-right",
                    });
                });
        },

        clearForm() {
            this.user = {
                email: "",
                password: "",
            };
        },
    },
};
</script>