<template>
    <section id="download" class="section bg-gray">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-8 col-xs-12">
                    <div class="download-wrapper">
                        <div>
                            <div class="download-text">
                                <h4>{{ appDownload.download_title || "" }}</h4>
                                <p>{{ appDownload.download_subtitle || "" }}</p>
                            </div>
                            <div class="app-button">
                                <a :href="appDownload.ios_download_link || '#'" class="btn btn-border">
                                    <img src="assets/img/icons/icons-01.png" />
                                </a>
                                <a :href="appDownload.andriod_download_link || '#'" class="btn btn-border">
                                    <img src="assets/img/icons/android-icon.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-4 col-xs-12">
                    <div v-if="appDownload.download_section_banner" class="download-thumb">
                        <img
                            class="img-fluid"
                            :src="appDownload.download_section_banner || 'assets/img/banner/mobile-app.png'"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState, mapActions } from "vuex";

    export default {

        computed: {
            ...mapState("appearance", ["appDownload"]),
        },

        mounted() {
            this.getAppDownloadSection();
        },

        methods: {
            ...mapActions("appearance", ["getAppDownloadSection"]),
        },
    };
</script>