<template>
    <div>
        <div class="mt-3">
            <div class="job-search-form">
                <form @submit.prevent="handleSearchForm">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-xs-12">
                            <div class="form-group">
                                <input v-model="form.query" class="form-control" type="text"
                                    placeholder="Job Title, Keyword or Employer" />
                                <i class="fas fa-edit"></i>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-xs-12">
                            <div class="form-group">
                                <div class="search-category-container">
                                    <label class="styled-select">
                                        <select v-model="form.atoll">
                                            <option value="" selected>Locations</option>
                                            <option v-for="option in options.atoll" :key="option.id" :value="option.name">
                                                {{ option.name }}
                                            </option>
                                        </select>
                                    </label>
                                </div>
                                <i class="fas fa-map-marker-alt"></i>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-3 col-xs-12">
                            <div class="form-group">
                                <div class="search-category-container">
                                    <i class="fas fa-edit"></i>
                                    <label class="styled-select">
                                        <select v-model="form.category_id">
                                            <option value="" selected>All Categories</option>
                                            <option v-for="option in options.category" :key="option.id" :value="option.id">
                                                {{ option.name }}
                                            </option>
                                        </select>
                                    </label>
                                </div>
                                <i class="fas fa-inbox"></i>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-xs-12">
                            <button type="submit" class="button">Search Now</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!--job type-->
        <div v-if="hasJobType || false" class="m-footer">
            <div class="job-search-form">
                <label class="round mr-3">
                    <input type="checkbox" name="full-time" /> Full Time
                    <span class="checkmark"></span>
                </label>
                <label class="round mr-3">
                    <input type="checkbox" name="part-time" /> Part Time
                    <span class="checkmark"></span>
                </label>
                <label class="round mr-3">
                    <input type="checkbox" name="temporary" /> Temporary
                    <span class="checkmark"></span>
                </label>
                <label class="round mr-3">
                    <input type="checkbox" name="remote" /> Remote
                    <span class="checkmark"></span>
                </label>
                <label class="round mr-3">
                    <input type="checkbox" name="work-for-home" /> Work For Home
                    <span class="checkmark"></span>
                </label>
                <label class="round mr-3">
                    <input type="checkbox" name="freelance" /> Freelance
                    <span class="checkmark"></span>
                </label>
                <label class="round mr-3">
                    <input type="checkbox" name="internship" /> Internship
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState, mapActions } from "vuex";

export default {
    props: {
        hasJobType: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            form: {
                query: '',
                atoll: '',
                category_id: '',
                employment_type: '',
            },
        };
    },

    computed: {
        ...mapState("optionProperty", ["options"]),
    },

    mounted() {
        this.getOptionProperty();
    },

    methods: {
        ...mapActions("optionProperty", ["getOptionProperty"]),
        ...mapActions("opportunity", ["searchOpportunities"]),

        handleSearchForm() {
            if (this.hasJobType || false) {
                this.$router.push({ name: 'opportunities' })
            }
            this.searchOpportunities(this.form);
        }
    },
};
</script>