<template>
    <div class="row">
        <div class="col-12 text-center mt-4">
            <ul class="pagination">
                <li class="active" :class="{ disabled: hasFirst }">
                    <a href="#" class="btn-prev" :disabled="hasFirst" @click="prev">
                        <i class="fas fa-arrow-left"></i> previous
                    </a>
                </li>

                <li v-show="rangeFirstPage !== 1">
                    <a href="#" @click="goto(1)">1</a>
                </li>

                <li v-show="rangeFirstPage === 3">
                    <a href="#" @click="goto(2)">2</a>
                </li>

                <li v-show="rangeFirstPage !== 1 && rangeFirstPage !== 2 && rangeFirstPage !== 3" class="disabled">
                    <a href="#">...</a>
                </li>

                <li v-for="page in range" :key="page">
                    <a href="#" :class="{ active: hasActive(page) }" @click="goto(page)">
                        {{ page }}
                    </a>
                </li>

                <li
                    v-show="
                        rangeLastPage !== totalPage &&
                        rangeLastPage !== totalPage - 1 &&
                        rangeLastPage !== totalPage - 2
                    "
                    class="page-item disabled"
                >
                    <a href="#">...</a>
                </li>

                <li v-show="rangeLastPage === totalPage - 2">
                    <a href="#" @click="goto(totalPage - 1)">
                        {{ totalPage - 1 }}
                    </a>
                </li>

                <li v-if="rangeLastPage !== totalPage">
                    <a href="#" @click="goto(totalPage)">
                        {{ totalPage }}
                    </a>
                </li>

                <li class="active" :class="{ disabled: hasLast }">
                    <a href="#" class="btn-next" :class="{ disabled: hasLast }" @click="next">
                        Next <i class="fas fa-arrow-right"> </i
                    ></a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            totalPage: Number,
            currentPage: Number,
            rangeMax: {
                type: Number,
                default: 3,
            },
        },

        mounted() {
            if (this.currentPage > this.totalPage) {
                this.$emit('page-changed', this.totalPage);
            }
        },

        computed: {
            rangeFirstPage() {
                if (this.currentPage === 1) {
                    return 1;
                }

                if (this.currentPage === this.totalPage) {
                    if ((this.totalPage - this.rangeMax) < 0) {
                        return 1;
                    }

                    return this.totalPage - this.rangeMax + 1;
                }

                return this.currentPage - 1;
            },

            rangeLastPage() {
                return Math.min(this.rangeFirstPage + this.rangeMax - 1, this.totalPage);
            },

            range() {
                let rangeList = [];

                for (let page = this.rangeFirstPage; page <= this.rangeLastPage; page += 1) {
                    rangeList.push(page);
                }

                return rangeList;
            },

            hasFirst() {
                return this.currentPage === 1;
            },

            hasLast() {
                return this.currentPage === this.totalPage;
            },
        },

        methods: {
            prev() {
                this.$emit('page-changed', this.currentPage - 1);
            },

            next() {
                this.$emit('page-changed', this.currentPage + 1);
            },

            goto(page) {
                if (this.currentPage !== page) {
                    this.$emit('page-changed', page);
                }
            },

            hasActive(page) {
                return page === this.currentPage;
            },
        },
    };
</script>
