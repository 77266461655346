<template>
    <div class="col-md-12">
        <div class="alphabet-filter mt-3">
            <ul class="alphabet-list">
                <li class="active"><a @click="getEmployers()">ALL</a></li>
                <li><a href="#">#</a></li>
                <li><a @click="getEmployersByAlpha('A')">A</a></li>
                <li><a @click="getEmployersByAlpha('B')">B</a></li>
                <li><a @click="getEmployersByAlpha('C')">C</a></li>
                <li><a @click="getEmployersByAlpha('D')">D</a></li>
                <li><a @click="getEmployersByAlpha('E')">E</a></li>
                <li><a @click="getEmployersByAlpha('F')">F</a></li>
                <li><a @click="getEmployersByAlpha('G')">G</a></li>
                <li><a @click="getEmployersByAlpha('H')">H</a></li>
                <li><a @click="getEmployersByAlpha('I')">I</a></li>
                <li><a @click="getEmployersByAlpha('J')">J</a></li>
                <li><a @click="getEmployersByAlpha('K')">K</a></li>
                <li><a @click="getEmployersByAlpha('L')">L</a></li>
                <li><a @click="getEmployersByAlpha('M')">M</a></li>
                <li><a @click="getEmployersByAlpha('N')">N</a></li>
                <li><a @click="getEmployersByAlpha('O')">O</a></li>
                <li><a @click="getEmployersByAlpha('P')">P</a></li>
                <li><a @click="getEmployersByAlpha('Q')">Q</a></li>
                <li><a @click="getEmployersByAlpha('R')">R</a></li>
                <li><a @click="getEmployersByAlpha('S')">S</a></li>
                <li><a @click="getEmployersByAlpha('T')">T</a></li>
                <li><a @click="getEmployersByAlpha('U')">U</a></li>
                <li><a @click="getEmployersByAlpha('W')">W</a></li>
                <li><a @click="getEmployersByAlpha('X')">X</a></li>
                <li><a @click="getEmployersByAlpha('Y')">Y</a></li>
                <li><a @click="getEmployersByAlpha('Z')">Z</a></li>
            </ul>
        </div>
    </div>
    <div id="filter" class="col-md-4">
        <div class="col-md-12">
            <div class="card p-4">
                <div class="close-button">
                    <button type="button" class="close filterToogle" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="widget">
                    <div class="search_widget_job">
                        <div class="field_w_search mt-3">
                            <input
                                type="text"
                                name="name"
                                :value="filter.name"
                                placeholder="Employer Name"
                                class="form-control"
                                @input="handleEmployersData"
                            />
                        </div>
                        <div class="field_w_search mt-3">
                            <input
                                type="text"
                                name="location"
                                :value="filter.location"
                                placeholder="Employer Location"
                                class="form-control"
                                @input="handleEmployersData"
                            />
                        </div>
                    </div>
                </div>
                <div class="widget">
                    <!--Employer Rating-->
                    <div class="sidebar-widget">
                        <h5 class="sb-title">Employer Rating</h5>
                        <div class="posted_widget">
                            <div class="simple-checkbox">
                                <div class="range_slider">
                                    <div
                                        class="nstSlider"
                                        data-range_min="0"
                                        data-range_max="5"
                                        data-cur_min="0"
                                        enable
                                        data-cur_max="5"
                                    >
                                        <div class="bar"></div>
                                        <div class="leftGrip"></div>
                                        <div class="rightGrip"></div>
                                    </div>
                                    <div class="leftLabel"></div>
                                    <div class="rightLabel float-right"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Team Size-->
                    <div class="sidebar-widget">
                        <h5 class="sb-title">Team Size</h5>
                        <div class="posted_widget">
                            <div class="simple-checkbox">
                                <div class="range_slider">
                                    <div
                                        class="nstSlider"
                                        data-range_min="1"
                                        data-range_max="50"
                                        data-cur_min="0"
                                        data-cur_max="50"
                                    >
                                        <div class="bar"></div>
                                        <div class="leftGrip"></div>
                                        <div class="rightGrip"></div>
                                    </div>
                                    <div class="leftLabel"></div>
                                    <div class="rightLabel float-right"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Years in Business-->
                    <div class="sidebar-widget">
                        <h5 class="sb-title">Years in Business</h5>
                        <div class="posted_widget">
                            <div class="simple-checkbox">
                                <div class="range_slider">
                                    <div
                                        class="nstSlider"
                                        data-range_min="1"
                                        data-range_max="20"
                                        data-cur_min="0"
                                        data-cur_max="20"
                                    >
                                        <div class="bar"></div>
                                        <div class="leftGrip"></div>
                                        <div class="rightGrip"></div>
                                    </div>
                                    <div class="leftLabel"></div>
                                    <div class="rightLabel float-right"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Employment Sector-->
                    <div class="sidebar-widget">
                        <h3 class="sb-title">Employment Sector</h3>
                        <div class="switches-list">
                            <div v-for="option in options.employment_sector" :key="option.id" class="switch-container">
                                <label class="switch">
                                    <input
                                        type="checkbox"
                                        :value="option.title"
                                        v-model="filter.employment_sector"
                                        @change="getEmployersData"
                                    />
                                    <span class="switch-button"></span>{{ option.title }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <!-- Industry-->
                    <div class="sidebar-widget">
                        <h3 class="sb-title">Industry</h3>
                        <div class="switches-list">
                            <div v-for="option in options.industry" :key="option.id" class="switch-container">
                                <label class="switch">
                                    <input
                                        type="checkbox"
                                        :value="option.title"
                                        v-model="filter.industry"
                                        @change="getEmployersData"
                                    />
                                    <span class="switch-button"></span>{{ option.title }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <!-- Field of Work-->
                    <div class="sidebar-widget">
                        <h3 class="sb-title">Field of Work</h3>
                        <div class="switches-list">
                            <div v-for="option in options.category" :key="option.id" class="switch-container">
                                <label class="switch">
                                    <input
                                        type="checkbox"
                                        :value="option.id"
                                        v-model="filter.category"
                                        @change="getEmployersData"
                                    />
                                    <span class="switch-button"></span>{{ option.name }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <!-- Ownership Types  -->
                    <div class="sidebar-widget">
                        <h3 class="sb-title">Ownership Types</h3>
                        <div class="switches-list">
                            <div v-for="option in options.ownership_type" :key="option.id" class="switch-container">
                                <label class="switch">
                                    <input
                                        type="checkbox"
                                        :value="option.title"
                                        v-model="filter.ownership_type"
                                        @change="getEmployersData"
                                    />
                                    <span class="switch-button"></span>{{ option.title }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <!-- Verified Employers-->
                    <div class="sidebar-widget">
                        <h3 class="sb-title">Verified Employers</h3>
                        <div class="switches-list">
                            <div class="switch-container">
                                <label class="switch"
                                    ><input type="checkbox" /><span class="switch-button"></span>Verified</label
                                >
                            </div>
                            <div class="switch-container">
                                <label class="switch"
                                    ><input type="checkbox" /><span class="switch-button"></span>Unverified</label
                                >
                            </div>
                        </div>
                    </div>

                    <!-- Average Salary-->
                    <div class="sidebar-widget">
                        <h3 class="sb-title">Average Salary</h3>
                        <div class="switches-list">
                            <div class="switch-container">
                                <label class="switch"
                                    ><input type="checkbox" /><span class="switch-button"></span>USD</label
                                >
                            </div>
                            <div class="switch-container">
                                <label class="switch"
                                    ><input type="checkbox" /><span class="switch-button"></span>MVR</label
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState, mapActions } from "vuex";

    export default {
        data() {
            return {
                filter: {
                    alpha: '',
                    name: '',
                    location: '',
                    rating: '',
                    team_size: '',
                    since: '',
                    employment_sector: [],
                    employment_type: [],
                    industry: [],
                    category: [],
                    ownership_type: [],
                    verified_employer: true,
                    average_salary: '',
                }
            };
        },

        computed: {
            ...mapState("optionProperty", ["options"]),
        },

        mounted() {
            this.getOptionProperty();
        },

        watch: {
            handleEmploymentSector(data) {
                console.log(data);
            }
        },

        methods: {
            ...mapActions("employer", ["getEmployers", "filterEmployers", "alphaFilterEmployers"]),
            ...mapActions("optionProperty", ["getOptionProperty"]),

            handleEmployersData(event) {
                this.filter[event.target.name] = event.target.value;
                this.filterEmployers(this.filter);
            },

            getEmployersByAlpha(alpha) {
                this.filter.alpha = alpha;
                this.filter.name = '';
                this.alphaFilterEmployers(this.filter.alpha || '');
            },

            clearFilter() {
                this.filter = {
                    alpha: '',
                    name: '',
                    location: '',
                    rating: '',
                    team_size: '',
                    since: '',
                    employment_sector: [],
                    employment_type: [],
                    industry: [],
                    category: [],
                    ownership_type: [],
                    verified_employer: true,
                    average_salary: '',
                };
                this.filterEmployers();
            }
        },
    };
</script>