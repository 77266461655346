<template>
    <app-header />
    <router-view />
    <app-footer />
</template>

<style scoped>
    @import "../../../public/account-assets/css/dashboard.css";
    @import "../../../public/assets/css/animate.css";
    @import "../../../public/assets/css/slicknav.min.css";
    @import "../../../public/assets/css/main.css";
    @import "../../../public/assets/css/main-2.css";
    @import "../../../public/assets/css/responsive.css";
    @import "../../../public/assets/css/style.css";
</style>

<script>
    import AppHeader from "./AppHeader.vue";
    import AppFooter from "./AppFooter.vue";

    export default {
        name: "AppLayout",

        components: {
            AppHeader,
            AppFooter,
        },
    };
</script>