<template>
    <div class="sidebar-dash" data-color="white" data-active-color="success">
        <div class="logo">
            <router-link to="/account" class="simple-text logo-mini">
                <div class="logo-image-small">
                    <img src="@/assets/logo.png" width="50" />
                </div>
            </router-link>
            <router-link to="/account" class="simple-text logo-normal">
                <div class="logo-image-big">
                    <img src="@/assets/logo.png" width="100" />
                </div>
            </router-link>
        </div>
        <div class="sidebar-dash-wrapper">
            <div class="user">
                <div class="photo">
                    <img :src="`https://ui-avatars.com/api/?background=random&name=${currentUser.user.name}`" />
                </div>
                <div v-if="currentUser.token" class="info">
                    <a data-toggle="collapse" href="#miniProfile" class="collapsed">
                        <span v-if="currentUser.token"> {{ currentUser.user.name }} <b class="caret"></b> </span>
                        <span class="text-truncate" style="font-size: 10px">
                            {{ currentUser.user.role || "" }}
                        </span>
                    </a>
                    <div class="clearfix"></div>
                    <div class="collapse" id="miniProfile">
                        <ul class="nav">
                            <li>
                                <router-link to="/account/profile">
                                    <span class="sidebar-dash-mini-icon">EP</span>
                                    <span class="sidebar-dash-normal">Edit Profile</span>
                                </router-link>
                            </li>
                            <li>
                                <a @click="appLogout" href="#">
                                    <span class="sidebar-dash-mini-icon">L</span>
                                    <span class="sidebar-dash-normal">Logout</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-if="currentUser.token && (accounts.length > 0)" class="user">
                <div class="d-flex">
                    <div v-if="selectedEmployer || false" class="photo mb-1">
                        <img :src="`https://ui-avatars.com/api/?background=random&name=${selectedEmployer.name || ''}`" />
                    </div>
                    <div class="select-employer-profile">
                        <select class="form-control" @change="(e) => onSelectGlobalEmployer(e.target.value)">
                            <option v-for="option in accounts" :key="option.id" :value="option.id">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <ul class="nav">
                <li :class="{ active: this.$route.name == 'account' }">
                    <router-link to="/account">
                        <i class="fa fa-th"></i>
                        <p>Dashboard</p>
                    </router-link>
                </li>
                <li :class="{ active: this.$route.name.includes('account-employer') }">
                    <router-link to="/account/employer">
                        <i class="fa fa-user"></i>
                        <p>Employer Profile</p>
                    </router-link>
                </li>
                <li :class="{ active: this.$route.name.includes('account-opportunity') }">
                    <router-link to="/account/opportunities">
                        <i class="fa fa-briefcase"></i>
                        <p>Job Opportunities</p>
                    </router-link>
                </li>
                <li :class="{ active: this.$route.name.includes('account-award') }">
                    <router-link to="/account/awards">
                        <i class="fa fa-shield"></i>
                        <p>Awards</p>
                    </router-link>
                </li>
                <li :class="{ active: this.$route.name.includes('account-announcement') }">
                    <router-link to="/account/announcements">
                        <i class="fa fa-bullhorn"></i>
                        <p>Announcements</p>
                    </router-link>
                </li>
                <li :class="{ active: this.$route.name.includes('account-photo') }">
                    <router-link to="/account/photos">
                        <i class="fa fa-picture-o"></i>
                        <p>Photos</p>
                    </router-link>
                </li>
                <li :class="{ active: this.$route.name.includes('account-certificate') }">
                    <router-link to="/account/certificates">
                        <i class="fa fa-certificate"></i>
                        <p>Certificate</p>
                    </router-link>
                </li>
                <li :class="{ active: this.$route.name.includes('account-team-roles') }">
                    <router-link to="/account/team-roles">
                        <i class="fa fa-users"></i>
                        <p>Roles</p>
                    </router-link>
                </li>
                <li :class="{ active: this.$route.name.includes('account-team-members') }">
                    <router-link to="/account/team-members">
                        <i class="fa fa-users"></i>
                        <p>Members</p>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    props: {
        title: String,
    },

    computed: {
        ...mapState("auth", ["currentUser"]),
        ...mapState("employer", ["accounts", "selectedEmployer"]),
    },

    mounted() {
        this.getAccountEmployers().then(() => {
            if (this.accounts.length > 0) {
                this.onSelectGlobalEmployer(this.accounts[0].id);
            }
        });
    },

    methods: {
        ...mapActions("auth", ["logout"]),
        ...mapActions("employer", ["getAccountEmployers", "onSelectGlobalEmployer"]),

        appLogout() {
            this.logout();
            this.$router.push({
                name: "home",
            }).then(() => window.location.reload());
        },
    }
};
</script>

