import $ from "jquery";

export default {
    mounted() {
        this.init();
    },
    
    methods: {
        
        init() {
            $(".nav-item-mobile-menu").on("click", () => {
                $('.responsive-header').removeClass('active');
                $('.responsive-opensec').slideUp();
                $('.res-openmenu').removeClass('active')
                $('.res-closemenu').addClass('active');
            });

            $('.res-openmenu').on('click', () => {
                $('.responsive-header').addClass('active');
                $('.responsive-opensec').slideDown();
                $('.res-closemenu').removeClass('active')
                $(this).addClass('active');
            });

            $('.res-closemenu').on('click', () => {
                $('.responsive-header').removeClass('active');
                $('.responsive-opensec').slideUp();
                $('.res-openmenu').removeClass('active')
                $(this).addClass('active');
            });
        },
    },
};

