<template>
    <section class="category section bg-gray">
        <div class="container">
            <div class="section-header">
                <h2 class="section-title">Popular Categories</h2>
                <p>Popular Categories {{ popularCategories.open_job || 0 }} open opportunities - {{
                    popularCategories.today_job }} added today.</p>
            </div>
            <div class="row mt-3">
                <div v-for="category in popularCategories.data" :key="category.id"
                    class="col-lg-3 col-md-6 col-xs-12 f-category">
                    <a href="#" @click="() => handleAction(category.id)">
                        <div v-if="category.file?.path || false" class="icon">
                            <img :src="category.file.path" />
                        </div>
                        <h3>{{ category.name || "" }}</h3>
                        <p>({{ category.number_of_job || 0 }} jobs)</p>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {

    computed: {
        ...mapState("appearance", ["popularCategories"]),
    },

    mounted() {
        this.getPopularCategories();
    },

    methods: {
        ...mapActions("appearance", ["getPopularCategories"]),

        handleAction(id) {
            this.$router.push({ name: 'opportunities', query: { categoryId: id } })
        },
    },
};
</script>