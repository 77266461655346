<template>
    <footer class="footer footer-black footer-white">
        <div class="container-fluid">
            <div class="row">
                <div class="credits ml-4">
                    <!-- <span class="copyright"> © 2022, made with <i class="fa fa-heart heart"></i> by Jobbank </span> -->
                </div>
            </div>
        </div>
    </footer>
</template>