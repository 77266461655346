<template>
    <nav class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">
        <div class="container-fluid">
            <div class="navbar-wrapper">
                <div class="navbar-toggle">
                    <button type="button" class="navbar-toggler">
                        <span class="navbar-toggler-bar bar1"></span>
                        <span class="navbar-toggler-bar bar2"></span>
                        <span class="navbar-toggler-bar bar3"></span>
                    </button>
                </div>
                <a v-if="currentUser.token" class="navbar-brand" href="#">{{ currentUser.user.name }}</a>
            </div>
            <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navigation"
                aria-controls="navigation-index"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-bar navbar-kebab"></span>
                <span class="navbar-toggler-bar navbar-kebab"></span>
                <span class="navbar-toggler-bar navbar-kebab"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end" id="navigation">
                <!-- <form action="#" method="get">
                    <div class="input-group no-border">
                        <input
                            type="text"
                            name="search_name"
                            class="form-control"
                            placeholder="Search ..."
                            value=""
                            required
                        />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <i class="nc-icon nc-zoom-split"></i>
                            </div>
                        </div>
                    </div>
                </form> -->
                <ul class="navbar-nav">
                    <li class="nav-item btn-magnify dropdown">
                        <a class="btn btn-common" target="_blank" href="/">
                            Home
                        </a>
                    </li>
                    <!-- <li class="nav-item btn-magnify dropdown">
                        <a
                            class="nav-link"
                            href="#"
                            id="navbarDropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <button class="btn btn-common" style="margin: 0">
                                <i class="fas fa-plus"></i> New
                            </button>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                            <router-link class="dropdown-item" to="/account/employer/create"><i class="nc-icon nc-briefcase-24"></i> Employer Profile</router-link>
                        </div>
                    </li> -->
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
    import { mapState } from "vuex";

    export default {
        props: {
            title: String,
        },

        computed: {
            ...mapState("auth", ["currentUser"]),
        },
    };
</script>

