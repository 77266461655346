<template>
    <div class="section-header recent-opportunities">
        <h2 class="opportunities-title m-footer">Recent Opportunities From Great Employers</h2>
        <h2 class="opportunities-title d-title">Recent Opportunities</h2>
        <p class="opportunities-text m-footer">
            We encourage jobs with some kind of flexibility options- such as part-time, remote jobs,<br />
            work for home, work for moms at home.
        </p>
        <p class="opportunities-text d-title">We encourage jobs with some kind of flexibility options.</p>
    </div>
</template>