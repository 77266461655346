<template>
    <section id="featured" class="section bg-cyan mt-3 employer-top">
        <div class="container">
            <div class="row">
                <div class="opportunities-top">
                    <h3>Great Employers Who HaveTrusted Us</h3>
                    <span class="ml-4">
                        {{ totalEmployers || 0 }} employers with {{ totalOpportunities || 0 }} oppertunities
                    </span>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-2 m-footer">
                    <div class="btn-group">
                        <button class="active listview"><i class="fa fa-bars"></i> List</button>
                        <button class="gridview"><i class="fa fa-th-large"></i> Grid</button>
                    </div>
                </div>

                <div class="col-md-3 m-sort-by">
                    <div class="sortby-sec mobile-filter-select">
                        <div class="float-left mr-3 mt-1 m-footer">
                            <p>Sort by</p>
                        </div>
                        <div class="float-left">
                            <select class="opportunities-select">
                                <option>Sort by</option>
                                <option>Most Recent</option>
                                <option>Apply Deadline</option>
                                <option>Top Jobs</option>
                                <option>Top Employers</option>
                                <option>Top Salary</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 m-sort-by">
                    <div class="alert-btn">
                        <button class="btn filterToogle"><i class="fas fa-sliders-h"></i>Show Filter</button>
                        <button class="btn m-footer"><i class="fa fa-envelope"></i> Get Job Alerts</button>
                    </div>
                </div>
            </div>

            <!--  job section-->
            <div class="row mt-3">
                <!-- filter section -->
                <employer-filter />

                <div id="filter-gridview" class="col-md-8">
                    <div class="row" v-if="employers.data?.length || false">
                        <employer-grid-view-card
                            v-for="employer in employers.data"
                            :key="employer.id"
                            :employer="employer"
                        />
                    </div>
                    <div v-else class="row justify-content-center mb-5 mt-3">
                        <empty title="0 Employer Profile Found!" />
                    </div>
                </div>

                <div id="filter-listview" class="col-md-12">
                    <div v-if="employers.data?.length || false">
                        <employer-list-view-card
                            v-for="employer in employers.data"
                            :key="employer.id"
                            :employer="employer"
                        />
                    </div>
                    <div v-else class="row justify-content-center mb-5 mt-3">
                        <empty title="0 Employer Profile Found!" />
                    </div>
                </div>
            </div>
            <v-pagination
                :total-page="Math.ceil(employers.total / employers.per_page)"
                :current-page="employers.current_page"
                @page-changed="getEmployers"
            >
            </v-pagination>
        </div>
    </section>
</template>

<script>
    import { mapState, mapActions } from "vuex";
    import EmployerListViewCard from "@/components/employers/EmployerListViewCard.vue";
    import EmployerGridViewCard from "@/components/employers/EmployerGridViewCard.vue";
    import VPagination from "@/components/utils/VPagination.vue";
    import Empty from "@/components/utils/Empty.vue";
    import AppMixin from "@/mixins/AppMixin.js";
    import EmployerFilter from "./components/EmployerFilter.vue";

    export default {
        name: "employers",

        components: { EmployerListViewCard, EmployerGridViewCard, VPagination, Empty, EmployerFilter },

        mixins: [AppMixin],

        computed: {
            ...mapState("employer", [
                "employers",
                "totalEmployers",
                "totalOpportunities",
            ]),
        },

        mounted() {
            this.getEmployers();
            this.countEmployers();
        },

        methods: {
            ...mapActions("employer", ["getEmployers", "countEmployers"]),
        }
    };
</script>