<template>
    <div>
        <section class="category">
            <div class="container-fluid bg-dark-gray p-5 mb-3">
                <div class="section-header">
                    <h2 class="client-title">Clients Who Have Trusted Us</h2>
                    <p class="client-text">The list of clients who put their trust in us includes</p>
                </div>
            </div>
        </section>
        <section class="clientlogo-section">
            <div v-if="clientWhoTrust?.length || false" class="container">
                <carousel :items-to-show="5" :autoplay="2000" :wrap-around="true">
                    <slide v-for="slide in clientWhoTrust" :key="slide">
                        <div v-if="slide.file?.path || false" class="item">
                            <img :src="slide.file.path" alt="" class="client-image" />
                        </div>
                    </slide>

                    <template #addons>
                        <navigation />
                    </template>
                </carousel>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapState, mapActions } from "vuex";
    import { Carousel, Navigation, Slide } from 'vue3-carousel';
    import 'vue3-carousel/dist/carousel.css';

    export default {

        components: {
            Carousel,
            Slide,
            Navigation,
        },

        computed: {
            ...mapState("appearance", ["clientWhoTrust"]),
        },

        mounted() {
            this.getClientWhoTrustSection();
            document.documentElement.style.setProperty('--vc-nav-background-color', '#8db750');
        },

        methods: {
            ...mapActions("appearance", ["getClientWhoTrustSection"]),
        },
    };
</script>
