<template>
    <div>
        <!--footer-->
        <footer>
            <section class="footer-Content">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-xs-12">
                            <div class="widget">
                                <div v-if="appContactInfo.info?.app_footer_logo || false" class="footer-logo">
                                    <img :src="appContactInfo.info.app_footer_logo" alt="" />
                                </div>
                                <div class="textwidget">
                                    <p v-if="appContactInfo.info?.app_location || false">
                                        {{ appContactInfo.info.app_location || "" }}
                                    </p>
                                    <p v-if="appContactInfo.info?.app_phone || false">
                                        {{ appContactInfo.info.app_phone || "" }}
                                    </p>
                                    <p v-if="appContactInfo.info?.app_email || false">
                                        {{ appContactInfo.info.app_email || "" }}
                                    </p>
                                </div>
                                <ul class="mt-3 footer-social">
                                    <li v-if="appContactInfo.social?.app_facebook || false">
                                        <a class="facebook" :href="appContactInfo.social?.app_facebook || '#'"
                                            ><i class="fab fa-facebook-f"></i
                                        ></a>
                                    </li>
                                    <li v-if="appContactInfo.social?.app_twitter || false">
                                        <a class="twitter" :href="appContactInfo.social?.app_twitter || '#'"
                                            ><i class="fab fa-twitter"></i
                                        ></a>
                                    </li>
                                    <li v-if="appContactInfo.social?.app_linkedin || false">
                                        <a class="linkedin" :href="appContactInfo.social?.app_linkedin || '#'"
                                            ><i class="fab fa-linkedin-in"></i
                                        ></a>
                                    </li>
                                    <li v-if="appContactInfo.social?.app_google || false">
                                        <a class="google-plus" :href="appContactInfo.social?.app_google || '#'"
                                            ><i class="fab fa-google-plus-g"></i
                                        ></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-xs-12 m-footer">
                            <div class="widget">
                                <h3 class="block-title">Quick Links</h3>
                                <ul class="menu">
                                    <li><a href="#">About Us</a></li>
                                    <li>
                                        <a href="contact.html">Contact Us</a>
                                    </li>
                                    <li><a href="#">Packages</a></li>
                                    <li><a href="#">Mobile App</a></li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-3 col-xs-12 m-footer">
                            <div class="widget">
                                <h3 class="block-title"></h3>
                                <ul class="menu">
                                    <li><a href="#">FAQs</a></li>
                                    <li><a href="#">Privacy & Cookies</a></li>
                                    <li><a href="#">Terms of Service</a></li>
                                    <li><a href="#">Blog</a></li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-3 col-xs-12 m-footer">
                            <div class="widget">
                                <h3 class="block-title"></h3>
                                <ul class="menu">
                                    <li><a href="#">Popular Fields</a></li>
                                    <li><a href="#">Featured Employers</a></li>
                                    <li><a href="#">Open Oppertunities</a></li>
                                    <li><a href="#">Featured Jobs</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div id="copyright">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="site-info">
                                <span v-if="appContactInfo.footer?.app_footer_text || false">{{
                                    appContactInfo.footer?.app_footer_text || "@ 2022 JobBank All Rights Reserved."
                                }}</span>
                                <div class="float-right text-white footer-link">
                                    <a
                                        v-if="appContactInfo.footer?.app_partner_1_link || false"
                                        :href="appContactInfo.footer?.app_partner_1_link || '#'"
                                        class="text-white pr-2"
                                        >{{ appContactInfo.footer?.app_partner_1_name || "" }}</a
                                    >
                                    <a
                                        v-if="appContactInfo.footer?.app_partner_2_link || false"
                                        :href="appContactInfo.footer?.app_partner_2_link || '#'"
                                        class="text-white pr-2"
                                        >{{ appContactInfo.footer?.app_partner_1_name || "" }}</a
                                    >
                                    <a
                                        v-if="appContactInfo.footer?.app_partner_3_link || false"
                                        :href="appContactInfo.footer?.app_partner_3_link || '#'"
                                        class="text-white pr-2"
                                        >{{ appContactInfo.footer?.app_partner_1_name || "" }}</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <login-popup />
        <register-popup />

        <a href="#" class="back-to-top">
            <i class="fas fa-arrow-up"></i>
        </a>

        <div id="preloader">
            <div class="loader" id="loader-1"></div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from "vuex";
    import LoginPopup from "@/components/auth/LoginPopup.vue";
    import RegisterPopup from "@/components/auth/RegisterPopup.vue";

    export default {

        components: {
            LoginPopup,
            RegisterPopup,
        },

        computed: {
            ...mapState("appearance", ["appContactInfo"]),
        },

        mounted() {
            this.getAppContactInfo();
        },

        methods: {
            ...mapActions("appearance", ["getAppContactInfo"]),
        },
    };
</script>