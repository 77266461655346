<template>
    <div class="hero-area container">
        <div class="row space-100">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="contents">
                    <h2 class="wow fadeInRight" data-wow-delay="0.4s">{{ heroSection.title || "" }}</h2>
                    <p class="wow fadeInRight" data-wow-delay="0.6s">
                        {{ heroSection.subtitle || "" }}
                    </p>
                    <a
                        v-if="heroSection.btn?.hero_section_first_btn_status || false"
                        :href="heroSection.btn?.hero_section_first_btn_url || ''"
                        class="btn btn-lg btn-common btn-effect wow fadeInRight"
                        data-wow-delay="0.9s"
                        >{{ heroSection.btn?.hero_section_first_btn_text || "" }}</a
                    >
                    <a
                        v-if="heroSection.btn?.hero_section_second_btn_status || false"
                        :href="heroSection.btn?.hero_section_second_btn_url || ''"
                        class="btn btn-lg btn-common btn-effect wow fadeInRight"
                        data-wow-delay="0.9s"
                        >{{ heroSection.btn?.hero_section_second_btn_text || "" }}</a
                    >
                </div>
            </div>

            <div class="col-lg-5 col-md-12 col-xs-12">
                <div class="intro-img">
                    <img :src="heroSection.file?.path || 'assets/img/banner/main-banner.png'" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from "vuex";

    export default {

        computed: {
            ...mapState("appearance", ["heroSection"]),
        },

        mounted() {
            this.getHeroSection();
        },

        methods: {
            ...mapActions("appearance", ["getHeroSection"]),
        },
    };
</script>