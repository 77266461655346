<template>
    <header id="home" class="hero-area">
        <!-- menu section-->
        <nav class="navbar navbar-expand-lg fixed-top scrolling-navbar">
            <div class="container">
                <div class="theme-header clearfix">
                    <div class="navbar-header">
                        <button
                            class="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#main-navbar"
                            aria-controls="main-navbar"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span class="navbar-toggler-icon"></span>
                            <span class="lni-menu"></span>
                            <span class="lni-menu"></span>
                            <span class="lni-menu"></span>
                        </button>
                        <router-link to="/" class="navbar-brand">
                            <img
                                v-if="appContactInfo.info?.app_logo"
                                :src="appContactInfo.info.app_logo"
                                alt=""
                                style="width: 140px"
                            />
                            <!-- <img v-else src="assets/img/logo.png" alt="logo" /> -->
                        </router-link>
                    </div>
                    <div class="collapse navbar-collapse" id="main-navbar">
                        <ul class="navbar-nav mr-auto w-100 justify-content-end">
                            <li class="nav-item dropdown">
                                <router-link to="/employers" class="nav-link">Employers</router-link>
                            </li>
                            <li class="nav-item dropdown">
                                <router-link to="/opportunities" class="nav-link">Opportunities</router-link>
                            </li>
                            <!-- <li class="nav-item dropdown">
                                <a
                                    class="nav-link dropdown-toggle"
                                    href="#"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    Talent Cloud
                                </a>
                            </li> -->
                            <li class="nav-item dropdown">
                                <router-link to="/pricing" class="nav-link"> Packages</router-link>
                            </li>
                            <li class="nav-item dropdown">
                                <router-link to="/contact" class="nav-link"> Contact Us</router-link>
                            </li>
                            <div v-if="currentUser.token" class="d-flex">
                                <li>
                                    <router-link to="/account" class="nav-link">
                                        <i class="fa fa-user"></i>
                                        {{ currentUser.user.name }}</router-link
                                    >
                                </li>
                                <li>
                                    <a @click="appLogout" class="nav-link" href="#">
                                        <i class="fa fa-sign-out-alt"></i>
                                        logout</a
                                    >
                                </li>
                            </div>
                            <div v-else class="d-flex">
                                <!-- <li class="nav-item">
                                    <a @click="openRegister" class="nav-link" href="#">Register</a>
                                </li>
                                <li class="nav-item">
                                    <a @click="openLogin" class="nav-link" href="#">Sign In</a>
                                </li> -->
                                <li class="button-group">
                                    <a @click="openLogin" class="button btn btn-common" href="#">Post a Job</a>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>

        <!-- mobile menu -->
        <div class="responsive-header">
            <div class="responsive-menubar">
                <div class="res-logo">
                    <a href="/" title="">
                        <img
                            v-if="appContactInfo.info?.app_logo"
                            :src="appContactInfo.info.app_logo"
                            alt=""
                            style="width: 100px"
                        />
                    </a>
                </div>
                <div class="menu-resaction">
                    <div class="res-openmenu">
                        <img src="assets/img/icons/icon.png" alt="" /> 
                        Menu
                    </div>
                    <div class="res-closemenu">
                        <img src="assets/img/icons/icon2.png" alt="" />
                        Close
                    </div>
                </div>
            </div>
            <div class="responsive-opensec">
                <div class="btn-extars">
                    <ul v-if="currentUser.token" class="account-btns pt-2">
                        <li class="open-button">
                            <router-link to="/account"><i class="fa fa-user"></i> Account</router-link>
                        </li>
                        <li class="open-button">
                            <a @click="appLogout"><i class="fa fa-sign-out-alt"></i> Logout</a>
                        </li>
                    </ul>
                    <ul v-else class="account-btns pt-2">
                        <li class="open-button">
                            <a @click="openRegister" class="pr-4"><i class="fa fa-key"></i>Register</a>
                        </li>
                        <li class="open-button">
                            <a @click="openLogin"><i class="fa fa-external-link-square"></i> Login</a>
                        </li>
                    </ul>
                </div>
                <!-- Btn Extras -->
                <div class="responsivemenu">
                    <ul class="navbar-nav mr-auto w-100 justify-content-end">
                        <li class="nav-item dropdown nav-item-mobile-menu active ">
                            <router-link to="/employers"> Employers</router-link>
                        </li>
                        <li class="nav-item dropdown nav-item-mobile-menu">
                            <router-link to="/opportunities"> Opportunities</router-link>
                        </li>
                        <!-- <li class="nav-item dropdown nav-item-mobile-menu">
                            <a class="" href="#">Talent Cloud</a>
                        </li> -->
                        <li class="nav-item dropdown nav-item-mobile-menu">
                            <router-link to="/pricing"> Packages</router-link>
                        </li>
                        <li class="nav-item dropdown nav-item-mobile-menu">
                            <router-link to="/contact"> Contact Us</router-link>
                        </li>
                        <!-- <li class="button-group">
                            <a @click="openLogin" class="button btn btn-common">Post a Job</a>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import { mapState, mapActions } from "vuex";
    import AuthMixin from "@/mixins/AuthMixin.js";
    import HeaderMixin from "@/mixins/HeaderMixin.js";

    export default {
        name: "app-header",

        mixins: [AuthMixin, HeaderMixin],

        computed: {
            ...mapState("auth", ["currentUser"]),
            ...mapState("appearance", ["appContactInfo"]),
        },

        methods: {
            ...mapActions("auth", ["logout"]),
            ...mapActions("appearance", ["getAppContactInfo"]),

            appLogout() {
                this.logout();
                this.$router.push({
                    name: "home",
                });
            }
        }
    };
</script>