export default {
    methods: {
        openRegister() {
            this.closeLogin();
            document.getElementById("registerForm").style.display = "block";
        },

        closeRegister() {
            document.getElementById("registerForm").style.display = "none";
        },

        openLogin() {
            this.closeRegister();
            document.getElementById("loginForm").style.display = "block";
        },

        closeLogin() {
            document.getElementById("loginForm").style.display = "none";
        },
    },
};

