<template>
    <div class="wrapper" id="dashboard-from">
        <account-layout-sidebar />
        <div class="main-panel">
            <account-layout-navbar />
            <div class="content">
                <router-view />
            </div>
            <account-layout-footer />
        </div>
    </div>
</template>

<script>
    import AccountLayoutFooter from "./components/Footer.vue";
    import AccountLayoutSidebar from "./components/Sidebar.vue";
    import AccountLayoutNavbar from "./components/Navbar.vue";

    export default {
        props: {
            title: String,
        },

        components: {
            AccountLayoutFooter,
            AccountLayoutSidebar,
            AccountLayoutNavbar,
        },
    };
</script>