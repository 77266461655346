import $ from "jquery";

export default {
    mounted() {
        this.init();
    },

    methods: {
        init() {
            let filterGridview = document.getElementById("filter-gridview");
            let filterListview = document.getElementById("filter-listview");
            filterListview.style.display = "none";
            $(".listview").on("click", function () {
                filterListview.style.display = "block";
                filterGridview.style.display = "none";
            });
            $(".gridview").on("click", function () {
                filterListview.style.display = "none";
                filterGridview.style.display = "block";
            });

            let filter = document.getElementById("filter");
            let column = document.getElementsByClassName("column");
            filterGridview.classList.remove("col-md-8");
            filterGridview.classList.add("col-md-12");

            $(".filterToogle").on("click", function () {
                if (filter.style.display === "none") {
                    filter.style.display = "block";
                    filterGridview.classList.remove("col-md-12");
                    filterGridview.classList.add("col-md-8");
                    filterListview.classList.remove("col-md-12");
                    filterListview.classList.add("col-md-8");
                    for (let i = 0; i < column.length; i++) {
                        column[i].classList.remove("col-lg-4");
                    }
                } else {
                    filter.style.display = "none";
                    filterGridview.classList.remove("col-md-8");
                    filterGridview.classList.add("col-md-12");
                    filterListview.classList.remove("col-md-8");
                    filterListview.classList.add("col-md-12");
                    for (let i = 0; i < column.length; i++) {
                        column[i].classList.add("col-lg-4");
                    }
                }
            });

            $('.posted_widget').slideUp();
            $('.switches-list').slideUp();
            $('.sb-title.open').next().slideDown();
            $('.sb-title.closed').next().slideUp();
            $('.sb-title').on('click', function () {
                $(this).next().slideToggle();
                $(this).toggleClass('active');
                $(this).toggleClass('closed');
            });

            this.range();
        },

        range() {
            // $('.nstSlider').nstSlider({
            //     "left_grip_selector": ".leftGrip",
            //     "right_grip_selector": ".rightGrip",
            //     "value_bar_selector": ".bar",
            //     "value_changed_callback": function (cause, leftValue, rightValue) {
            //         $(this).parent().find('.leftLabel').text(leftValue);
            //         $(this).parent().find('.rightLabel').text(rightValue);
            //     }
            // });
        },

    },
}

