<template>
    <account-layout v-if="this.$route.meta.layout == 'account'" title="Dashboard" />
    <app-layout v-else />
</template>

<script>
    import AppLayout from "./components/layouts/Index.vue";
    import AccountLayout from "./views/account/layouts/Index.vue";

    export default {
        name: "App",

        components: {
            AppLayout,
            AccountLayout,
        },
    };
</script>
