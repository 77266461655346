<template>
    <div class="column col-lg-4 col-md-6 col-xs-12">
        <div class="job-featured">
            <div class="row mb-2">
                <div class="job-logo" v-if="employer.file?.path || false">
                    <img :src="employer.file.path" alt="" class="max-w-100px" />
                </div>
            </div>
            <div class="col-md-12">
                <div class="content text-center">
                    <div class="company-name">
                        <router-link
                            :to="{
                                name: 'employer-show',
                                params: { id: employer.id },
                            }"
                        >
                            <h3>{{ employer.name }}</h3></router-link
                        >
                    </div>
                    <div class="mt-1 company-location" v-if="employer.location || false">
                        <span>
                            <i class="fas fa-map-marker-alt"></i>
                            {{ `${employer.location.address || ""}, ${employer.location.atoll.name || ""}` }}
                        </span>
                    </div>
                    <div class="mt-2 conpany-info">
                        <span><i class="fas fa-briefcase"> </i> {{ employer.employment_sector || "Sector" }}</span>
                    </div>
                    <div class="mt-2 conpany-info" v-if="employer.since">
                        <span class="mr-3"><i class="fas fa-user-times"> </i> {{ "Since " + employer.since }}</span>
                        <span class=""><i class="fas fa-users"> </i> {{ employer.team_size || 1 }}</span>
                    </div>
                    <div class="job-btn mt-3 mb-3">
                        <router-link
                            :to="{
                                name: 'employer-show',
                                params: { id: employer.id },
                            }"
                        >
                            <button class="btn">View Full Profile</button>
                        </router-link>
                    </div>
                    <div class="mt-2 conpany-info follow-button">
                        <span class="mr-3"><i class="far fa-heart"></i> Follow</span>
                        <span><i class="far fa-bookmark"></i> {{ employer.open_opportunities || 0 }} Open Jobs</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>

<script>
    export default {
        props: ["employer"],
    };
</script>