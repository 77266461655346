<template>
    <div>
        <div class="container">
            <employer-info :employer="singleEmployer" />
        </div>

        <section class="pb-5">
            <div class="block">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 column">
                            <div class="job-wide-devider">
                                <div class="row">
                                    <div class="col-lg-8 column">
                                        <div class="job-details">
                                            <div
                                                v-if="singleEmployer.description || ''"
                                                v-html="singleEmployer.description"
                                            ></div>
                                        </div>

                                        <employer-activity :employer="singleEmployer" />
                                    </div>
                                    <div class="col-lg-4 column">
                                        <employer-overview :employer="singleEmployer" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapState, mapActions } from "vuex";
    import EmployerInfo from "./components/EmployerInfo.vue";
    import EmployerOverview from "./components/EmployerOverview.vue";
    import EmployerActivity from "./components/EmployerActivity.vue";

    export default {
        components: { EmployerInfo, EmployerOverview, EmployerActivity },

        computed: {
            ...mapState("employer", ["singleEmployer"]),
        },

        mounted() {
            this.getEmployerById(this.$route.params.id);
        },

        methods: {
            ...mapActions("employer", ["getEmployerById"]),
        },
    };
</script>