<template>
    <section class="how-it-works section">
        <div class="container">
            <div class="section-header">
                <h2 class="section-title">Why Choose Us?</h2>
                <p>{{ whyChooseUs.why_choose_us_subtitle }}</p>
            </div>
            <div class="row mt-3">
                <div v-for="item in whyChooseUs.data" :key="item.id" class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div class="work-process">
                        <span v-if="item.file?.path || false" class="process-icon">
                            <img :src="item.file.path" />
                        </span>
                        <h4>{{ item.title || "" }}</h4>
                        <p>{{ item.subtitle || "" }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState, mapActions } from "vuex";

    export default {

        computed: {
            ...mapState("appearance", ["whyChooseUs"]),
        },

        mounted() {
            this.getWhyChooseUsSection();
        },

        methods: {
            ...mapActions("appearance", ["getWhyChooseUsSection"]),
        },
    };
</script>
