<template>
    <section class="category section bg-dark-gray">
        <div class="container">
            <recent-opportuity-header />

            <!-- search form -->
            <opportunity-search-from :hasJobType="true" />

            <!-- recent job show-->
            <div class="row mt-3 home-recent-job">
                <div v-if="recentOpportunities.length || 0" class="col-lg-12 col-md-12 col-xs-12">
                    <opportunity-recent-view-card
                        v-for="opportunity in recentOpportunities"
                        :key="opportunity.id"
                        :opportunity="opportunity"
                    />
                </div>
                <div v-else class="col-lg-12 col-md-12 col-xs-12 mt-3">
                    <empty title="0 Opportunities Found!" />
                </div>
                <div class="col-12 text-center mt-5">
                    <router-link :to="{ name: 'opportunities' }" class="btn btn-border">Load More</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

    import { mapState, mapActions } from "vuex";
    import OpportunityRecentViewCard from "@/components/opportunities/OpportunityRecentViewCard.vue";
    import Empty from "@/components/utils/Empty.vue";
    import RecentOpportuityHeader from "./../../opportunities/components/RecentOpportuityHeader.vue";
    import OpportunitySearchFrom from "./../../opportunities/components/OpportunitySearchForm.vue";

    export default {
        name: "home",

        components: {
            OpportunityRecentViewCard,
            Empty,
            RecentOpportuityHeader,
            OpportunitySearchFrom,
        },

        computed: {
            ...mapState("optionProperty", ["options"]),
            ...mapState("opportunity", ["recentOpportunities"]),
        },

        mounted() {
            this.getOptionProperty();
            this.getRecentOpportunities();
        },

        methods: {
            ...mapActions("optionProperty", ["getOptionProperty"]),
            ...mapActions("opportunity", ["getRecentOpportunities"]),

        },
    };
</script>