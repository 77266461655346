<template>
    <div class="my-4">
        <img src="@/assets/logo.png" alt="" class="img-fluid d-block img-center" />
        <div class="text-primary text-center d-block mt-3">
            {{ title || "" }}
        </div>
    </div>
</template>

<style scoped>
    .img-center {
        margin: 0 auto;
    }
</style>

<script>
    export default {
        props: {
            title: String,
        },
    };
</script>